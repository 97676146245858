.moz-bg-danger {
  background-color: #ffa79e !important;
}
.moz-bg-danger-1 {
  background-color: #fc529b !important;
}
.moz-bg-primary {
  background-color: #8a4de6 !important;
}
.moz-bg-primary-dark {
  background-color: #4f2f92 !important;
}
.dx-popup-content {
  padding: 0% !important;
  overflow-y: auto;
}
.dx-fieldset {
  margin: 0px !important;
}

.dx-texteditor-input {
  font-weight: 600;
  text-transform: uppercase;
}

.subnavigation-wrapper {
  box-shadow: 5px 0px 5px 0.5px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  // height: 39px;
  z-index: 99;
  position: relative;
  // margin-top: -39px;
  left: 0;
  width: 100%;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.card-2 {
  // 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05)
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0px 6px rgba(0, 0, 0, 0.05);
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}
.card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

edit-icon {
  background: url("../images/edit_icon.svg") no-repeat;
  background-position: 10px 20px;
}

// custom CSS for sidebar
.sidebar .nav li > a {
  height: 45px;
  display: flex !important;
  align-items: center;
  transition-duration: 0.45s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  // -webkit-transition-property: color;
}

.sidebar .nav li > a > span {
  margin-left: 10px;
}

.sidebar .nav .nav-link .list-group {
  background: rgba(255, 255, 255, 0.1);
}

@mixin shrinkLabel {
  top: -10px;
  font-size: 14px;
  color: #c450c1 !important;
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
}

.form-input-label {
  color: grey;
  font-size: 14px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 30px;
  top: 10px;
  transition: 100ms ease all;
  &.shrink {
    @include shrinkLabel();
    pointer-events: all;
  }
}
@media (max-width: 1400px) {
  .form-input-label {
    &.shrink {
      font-size: 12px;
    }
  }
}
@media (max-width: 1000px) {
  .form-input-label {
    &.shrink {
      font-size: 10px;
    }
  }
}
@media (max-width: 800px) {
  .form-input-label {
    &.shrink {
      font-size: 10px;
    }
  }
}

.form-input-label {
  font-size: 14px;
}

.form-control {
  font-size: 1.1rem !important;
}
.css-1uccc91-singleValue {
  // font-size: 1.1rem !important;
  color: grey !important;
  // height: calc(1.5em + 0.8rem + 2px) !important;
}

.Shrink-input {
  top: 15px;
  font-size: 14px;
  color: #c450c1 !important;
  background: #fff;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
  position: relative;
  z-index: 9;
  left: 17px;
}

// datepicker

.react-datepicker__input-container > input {
  border-color: #90a8be !important;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057 !important;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.editProfile:hover {
  background-color: #f2f2f2;
  border-radius: 50%;
  cursor: pointer;

  // width:25px;
  // font-size:16px;
}

.editProfile .icon:hover {
  // width:30px;
  // height: 30px;
  // color:honeydew;
  background: #f2f2f2;
  border-radius: 50%;
}

.btn-custom-light {
  color: #59626b;
  background-color: #eceff2;
  border-color: #eceff2;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-custom-light:hover {
  color: #fff;
  background-color: #9a82df;
  border-color: #9a82df;
}

.btn-custom-light-active {
  color: #fff !important;
  background-color: #9a82df !important;
  border-color: #9a82df !important;
}

.cursor-pointer {
  cursor: pointer;
}

.home-page {
  section {
    min-height: 500px;
  }
  .footer {
    min-height: 550px;
  }
}

/* >>>>>>>>>>>>>> Switch Button start */

/** Switch
 -------------------------------------*/
.container-switch {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 10px;
  align-items: center;
  margin: 10px 0;
  label {
    margin-bottom: 0;
  }
  .title {
    color: #813186 !important;
    font-weight: bold;
    font-size: 14px;
    padding-right: 5px;
  }
}

.switch input {
  position: absolute;
  opacity: 0;
}

.switch {
  display: inline-block;
  font-size: 20px; /* 1 */
  height: 20px;
  width: 40px;
  background: gray;
  border-radius: 1em;
  cursor: pointer;
}

.switch-active {
  background: #813186;
}

.switch div {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #fff;
  box-shadow: 0 0.1em 0.3em rgba(0, 0, 0, 0.3);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  transition: all 300ms;
}

.switch input:checked + div {
  -webkit-transform: translate3d(100%, 0, 0);
  -moz-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}
/* >>>>>>>>>>>>>> Switch Button end */

.feed {
  // flex: 1;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}
.post-wrapper {
  padding: 10px 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.free_biodata_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.button_create_biodata {
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
