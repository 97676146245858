/* sidebar */
.sidebarRow {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    margin: 5px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}
.sidebarRow:hover {
    background: rgba(255, 255, 255, 0.23);
    border-radius: 10px;
    
}

/* avatar */
.sidebarRow .MuiSvgIcon-root {
    font-size: xx-large;
    color: #c188de;
}

/* icon */


/* title */
.sidebarRow h4 {
    margin-left: 20px;
    font-weight: 600;
}
