/* scroll bar design start */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar:horizontal {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8b6ba1;
  opacity: 0.2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d4988;
}
/* scroll bar design end */

.sidebar-bg-gradient1 {
  background: linear-gradient(
    225deg,
    rgba(200, 80, 192, 1) 0%,
    rgba(166, 82, 196, 1) 34%,
    rgba(65, 88, 208, 1) 100%
  ) !important;
}
.sidebar .nav a,
.table > tbody > tr .td-actions .btn {
  -webkit-transition: all 150ms ease-in;
  -moz-transition: all 150ms ease-in;
  -o-transition: all 150ms ease-in;
  -ms-transition: all 150ms ease-in;
  transition: all 150ms ease-in;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}
/* working in current page */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  right: auto;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  background-size: cover;
  background-position: center center;
}
/* working in current page */
.sidebar .sidebar-wrapper {
  position: relative;
  max-height: 100vh;
  min-height: 100%;
  z-index: 4;
  padding-bottom: 100px;
}
/* working in current page */
.sidebar .sidebar-background {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
}
/* working in current page */
.sidebar .logo {
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar .logo p {
  float: left;
  font-size: 20px;
  margin: 10px 10px;
  color: #ffffff;
  line-height: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.sidebar .logo .simple-text {
  text-transform: uppercase;
  padding: 5px 0px;
  display: block;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  line-height: 30px;
}

.sidebar .logo-tim {
  border-radius: 50%;
  border: 1px solid #333;
  display: block;
  height: 61px;
  width: 61px;
  float: left;
  overflow: hidden;
}

.sidebar .logo-tim img {
  width: 60px;
  height: 60px;
}

.sidebar .nav-wrapper {
  margin-top: 20px;
  float: none;
}

.sidebar .nav .open > a,
.sidebar .nav li.dropdown .dropdown-menu li:hover > a,
.sidebar .nav li:hover > a {
  background-color: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.sidebar .nav li > a {
  color: #ffffff;
  margin: 5px 15px;
  opacity: 0.86;
  border-radius: 4px;
  display: block;
}

.sidebar .nav li.active > a {
  color: #ffffff;
  opacity: 1;
  background: rgba(255, 255, 255, 0.23);
}

.sidebar .nav li.separator {
  margin: 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.sidebar .nav li.separator + li {
  margin-top: 31px;
}

.sidebar .nav p {
  margin: 0;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 45px;
}

.sidebar .nav i {
  font-size: 28px;
  float: left;
  margin-right: 15px;
  line-height: 30px;
  width: 30px;
  text-align: center;
}

.sidebar .nav .caret {
  margin-top: 13px;
  position: absolute;
  right: 30px;
}

.sidebar:after,
.sidebar:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

.sidebar:before {
  opacity: 0.33;
  background: #000000;
}

.sidebar:after {
  background: #787878;
  background: -moz-linear-gradient(top, #787878 0%, #343434 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #787878),
    color-stop(100%, #343434)
  );
  background: -webkit-linear-gradient(top, #787878 0%, #343434 100%);
  background: -o-linear-gradient(top, #787878 0%, #343434 100%);
  background: -ms-linear-gradient(top, #787878 0%, #343434 100%);
  background: linear-gradient(to bottom, #787878 0%, #343434 100%);
  background-size: 150% 150%;
  z-index: 3;
  opacity: 1;
}

.sidebar[data-image]:after,
.sidebar.has-image:after {
  opacity: 0.77;
}

.sidebar[data-color="blue"]:after {
  background: #1f77d0;
  background: -moz-linear-gradient(top, #1f77d0 0%, #533ce1 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #1f77d0),
    color-stop(100%, #533ce1)
  );
  background: -webkit-linear-gradient(top, #1f77d0 0%, #533ce1 100%);
  background: -o-linear-gradient(top, #1f77d0 0%, #533ce1 100%);
  background: -ms-linear-gradient(top, #1f77d0 0%, #533ce1 100%);
  background: linear-gradient(to bottom, #1f77d0 0%, #533ce1 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="azure"]:after {
  background: #1dc7ea;
  background: -moz-linear-gradient(top, #1dc7ea 0%, #4091ff 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #1dc7ea),
    color-stop(100%, #4091ff)
  );
  background: -webkit-linear-gradient(top, #1dc7ea 0%, #4091ff 100%);
  background: -o-linear-gradient(top, #1dc7ea 0%, #4091ff 100%);
  background: -ms-linear-gradient(top, #1dc7ea 0%, #4091ff 100%);
  background: linear-gradient(to bottom, #1dc7ea 0%, #4091ff 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="green"]:after {
  background: #87cb16;
  background: -moz-linear-gradient(top, #87cb16 0%, #6dc030 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #87cb16),
    color-stop(100%, #6dc030)
  );
  background: -webkit-linear-gradient(top, #87cb16 0%, #6dc030 100%);
  background: -o-linear-gradient(top, #87cb16 0%, #6dc030 100%);
  background: -ms-linear-gradient(top, #87cb16 0%, #6dc030 100%);
  background: linear-gradient(to bottom, #87cb16 0%, #6dc030 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="orange"]:after {
  background: #ffa534;
  background: -moz-linear-gradient(top, #ffa534 0%, #ff5221 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #ffa534),
    color-stop(100%, #ff5221)
  );
  background: -webkit-linear-gradient(top, #ffa534 0%, #ff5221 100%);
  background: -o-linear-gradient(top, #ffa534 0%, #ff5221 100%);
  background: -ms-linear-gradient(top, #ffa534 0%, #ff5221 100%);
  background: linear-gradient(to bottom, #ffa534 0%, #ff5221 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="red"]:after {
  background: #fb404b;
  background: -moz-linear-gradient(top, #fb404b 0%, #bb0502 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #fb404b),
    color-stop(100%, #bb0502)
  );
  background: -webkit-linear-gradient(top, #fb404b 0%, #bb0502 100%);
  background: -o-linear-gradient(top, #fb404b 0%, #bb0502 100%);
  background: -ms-linear-gradient(top, #fb404b 0%, #bb0502 100%);
  background: linear-gradient(to bottom, #fb404b 0%, #bb0502 100%);
  background-size: 150% 150%;
}

.sidebar[data-color="purple"]:after {
  background: #9368e9;
  background: -moz-linear-gradient(top, #9368e9 0%, #943bea 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #9368e9),
    color-stop(100%, #943bea)
  );
  background: -webkit-linear-gradient(top, #9368e9 0%, #943bea 100%);
  background: -o-linear-gradient(top, #9368e9 0%, #943bea 100%);
  background: -ms-linear-gradient(top, #9368e9 0%, #943bea 100%);
  background: linear-gradient(to bottom, #9368e9 0%, #943bea 100%);
  background-size: 150% 150%;
}

.sidebar {
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}
.navbar .navbar-toggler {
  display: none;
}

@media (min-width: 992px) {
  .sidebar .nav-mobile-menu {
    display: none;
  }
}

/*          Changes for small display      */
@media (max-width: 991px) {
  .navbar-collapse,
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    right: 0;
    /* width: 100%; */
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform: translate3d(260px, 0, 0);
    -o-transform: translate3d(260px, 0, 0);
    -ms-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse > ul,
  .sidebar > ul {
    position: relative;
    z-index: 4;
    overflow-y: scroll;
    height: calc(100vh - 61px);
    width: 100%;
  }
  .navbar-collapse::before,
  .sidebar::before {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    /* background-color: #282828; */
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar-collapse .logo,
  .sidebar .logo {
    position: relative;
    z-index: 4;
  }
  .navbar-collapse .nav li > a,
  .navbar-collapse .nav,
  .sidebar .nav {
    margin-top: 10px;
  }
  .nav-open .navbar-collapse,
  .nav-open .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .sidebar-show {
    position: fixed !important;
    display: block !important;
    top: 0;
    height: 100%;
    right: none !important;
    /* width: 100%; */
    left: auto;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding: 0;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
    -webkit-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  #navbar-user-dropdown {
    display: none;
  }

  .navbar .navbar-toggler {
    display: block;
  }

  .navbar .navbar-mobile-toggler-show {
    position: relative;
    right: 250px;
    /* top: 10px; */
  }
}

@media (min-width: 992px) {
  .table-full-width {
    margin-left: -15px;
    margin-right: -15px;
  }
  .table-responsive {
    overflow: visible;
  }
  .navbar-nav p {
    line-height: normal;
    margin: 0;
  }
}

@media (max-width: 991px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}
